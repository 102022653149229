import React, { useEffect, useRef, useState } from 'react';
import { 
    fetchConversationData, 
    fetchConversationMessages, 
    markConversationSeen
} from 'Services/ConversationService';
import MessageForm from './MessageForm';
import ChatMessage from './ChatMessage';
import CONFIG from 'config';

const ChatBox = (props) => {
    
    let {conversationId, user_id, user_type_id, viewComponents } = props;
    
    /**
     * UI Elements
     */
    const Header = viewComponents.header;
    const Message = viewComponents.message || ChatMessage;
    const ChatInfo = viewComponents.info;
    // const MessageForm = viewComponents.form;

    const [ data, setData] = useState(null);
    const [messages, setMessages] = useState([]);
    const [consultantMember, setConsultantMember] = useState(null);
    const [lastSeenTime, setLastSeenTime] = useState(null);

    const [connected, setConnected] = useState(false);
    const [retryCount, setRetryCount] = useState(0);

    const [body, setBody] = useState('');
    const [files, setFiles] = useState(null);

    // const [localWs, setLocalWS] = useState(null);
    const localWs = useRef(null);

    const msgContainerRef = useRef();

    const loadData = async () => {
        let resp = await fetchConversationData(conversationId);
        // console.log("chat data",resp);
        setData(resp.data);
        if (Array.isArray(resp?.data?.members)){
            let consultantMember = resp?.data?.members.find(m => m.user_type_id === 2);
            if(typeof consultantMember !== 'undefined'){
                setConsultantMember(consultantMember);
                setLastSeenTime(consultantMember.last_seen_time);
            }
        }
    }

    const loadMessages = async () => {
        let resp = await fetchConversationMessages(conversationId);
        setMessages(resp.data);
        connect();
        setTimeout(() => scrollToLatestMessage(), 500);
    }

    const markSeen = async () => {
        markConversationSeen(conversationId, user_id, user_type_id);
        const msg = {
            type: 'seen',
            conversation_id: conversationId,
            user_id: user_id
        };
        if (localWs.current !== null) {
            localWs.current.send(JSON.stringify(msg));
        } else {
            connect(() => {
                localWs.current.send(JSON.stringify(msg));
            })
        }
    }

   

    const sendMessage = async () => {

    
        let requirement_title = null;
        let lead = null;
        let lead_id = null;


        if (data.response && data.response.requirement_title) {
            requirement_title = data.response.requirement_title;
        }

        if (data.response && data.response.lead && data.response.lead.name) {
            lead = data.response.lead.name;
            lead_id = data.response.lead.id;
        }

        const msg = {
            id: Date.now(),
            body: body,
            files: files,
            conversation_id: conversationId,
            sender_user_id: user_id,
            sender_user_type_id: user_type_id,
            createdAt: new Date().toISOString(),
            requirement_title: requirement_title,
            lead,
            lead_id,
            scheduled_by: "Admin",
        };
        if(localWs.current !== null){
            localWs.current.send(JSON.stringify(msg));
        } else {
            connect(() => {
                localWs.current.send(JSON.stringify(msg));
            })
        }
        
        setBody('');
    }

    const registerChat = () => {
        if (localWs.current) {
            localWs.current.send(
                JSON.stringify({
                    user_id: user_id,
                    conversation_id: conversationId,
                    type: "register",
                })
            );
        } else {
            connect();
        }
    }

    const appendMessage = (msg) => {
        setMessages(_messages => {
            // console.log("in set messages", _messages.length);
            let _arr = JSON.parse(JSON.stringify(_messages));
            _arr.push(msg);
            return _arr;
        })
    }

    const connect = (callback) => {

        if(localWs.current !== null){
            if(typeof callback === 'function'){
                callback();
            } else {
                registerChat();
            }
            return;
        }

        // console.log('connecting ws ...', process.env.CHAT_ENDPOINT);
        // const url = `wss://api.strategyconnect.co/websocket/`;
        // const url = process.env.CHAT_ENDPOINT;
        const url = CONFIG.CHAT_ENDPOINT;
        const ws = new WebSocket(url);
        localWs.current = ws;
        ws.onopen = () => {
            setConnected(true);
            ws.send(JSON.stringify({
                user_id: user_id,
                conversation_id: conversationId,
                type: 'register'
            }));
            if(typeof callback === 'function'){
                callback();
            }
        };
        ws.onmessage = (msg_event) => {
            let msg = msg_event.data;
            let msg_obj = JSON.parse(msg);
            console.log('message type', msg_obj.type, msg_obj.body);
            if(msg_obj.type === 'seen'){
                console.log('seen ping from user', msg_obj.user_id, consultantMember !== null && msg_obj.user_id != consultantMember.user_id, 'msg user_id', msg_obj.user_id, 'consultant user', consultantMember.user_id);
                if (consultantMember !== null && msg_obj.user_id == consultantMember.user_id) {
                    setLastSeenTime(Date.now());
                }
            } else {
                appendMessage(msg_obj);
                setTimeout(() => {
                    scrollToLatestMessage();
                }, 100);

                // this is not own message
                if(msg_obj.sender_user_id != user_id){
                    // ping back so that everyone knows that the message is seen
                    console.log({ user_id }, 'sending seen ping');
                    markSeen();
                }
                
            }
            

        }
        ws.onerror = (e) => {
            console.log('connection error', e);
            localWs.current = null;
            setConnected(false);

            
            const _itvl_ = setInterval(() => {
                if (retryCount > 10) {
                    clearInterval(_itvl_);
                    return;
                }
                setRetryCount(retryCount + 1);
                connect(() => clearInterval(_itvl_));
            }, 1000);
        }
        ws.onclose = (e) => {
            console.log("onclose param",e);
            localWs.current = null;
            if (e.code === 1000 || e.reason === 'COMPONENT_UNMOUNT'){
                return;
            }
            setConnected(false);

            console.log('connection closed');
            const _itvl_ = setInterval(() => {
                if (retryCount > 10) {
                    clearInterval(_itvl_);
                    return;
                }
                setRetryCount(retryCount + 1);
                connect(() => clearInterval(_itvl_));
            }, 2000);
        }

        
    }

    const scrollToLatestMessage = () => {
        const msgDiv = msgContainerRef.current;
        if (msgDiv) {
            msgDiv.scrollTop = msgDiv.scrollHeight;
        }
        markSeen();
    }

    useEffect(() => {
        console.log('chatbox mounted')
        loadData();
        loadMessages();
        return () => {
            if(localWs.current){
                localWs.current.close(1000, "COMPONENT_UNMOUNT");
            }
        }
    }, []);

    useEffect(() => {
        // console.log("messages changed", messages);
    }, [messages])

    // console.log({user_type_id}, messages.length, data === null);

    if(data === null) {
        return (
            <div
                style={{ width: 400, height: '100%', maxHeight: '100vh', display: 'flex', flexDirection: 'column' }}
                role="presentation"
            ></div>
        )
    }

    if(props.isMinimized){
        return <Header data={data} />
    }

    return (
        <div
            style={{ width: 400, height: '100%', maxHeight: '100vh', display: 'flex', flexDirection: 'column' }}
            role="presentation"
        >
            <Header data={data} />
            <ChatInfo data={data} />
            <div style={{ padding: '1rem', background: '#F9F9FB', flexGrow: 1, overflow: 'scroll' }} ref={msgContainerRef}>
                {messages.map((msg, k) => {
                    const is_sender = msg.sender_user_type_id === user_type_id;
                    const sender = data.members.find( m => m.user_id == msg.sender_user_id) || {};
                    const sender_name = sender.name;
                    return (
                        <div 
                            style={{ 
                                padding: '0.5rem', 
                                display: 'flex',
                                // marginTop: '0.5rem',
                                
                                justifyContent: is_sender ? 'flex-end' : 'flex-start' 
                            }} 
                            key={msg.id}
                        >
                            <Message 
                                lastSeenTime={lastSeenTime}
                                is_sender={is_sender}
                                sender_name={sender_name}
                                data={data}
                                msg={msg} />
                        </div>
                    )
                })}
            </div>
            <MessageForm 
                text={body} 
                onTextChange={setBody}
                sendMessage={sendMessage}
                setFiles={setFiles} />
        </div>
    )

}

export default ChatBox;