import React, { useState, useEffect } from "react";
import { usertype, authUser } from "Utils";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";

import { useTheme, useMediaQuery, Grid, Box, Button, TextField, Avatar, Typography, IconButton } from "Assets/Material";
import { useRecoilState, toasterGlobal, searchTermValue, searchByValue } from "Atom";
import { fetchConversations } from "Services/ConversationService";

import { FileUploadIcon, ChatSend } from "Assets/Icons";

// import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import ChatBox from "./components/ChatBox";

import { FetchSignURLService } from "Services/FileDownloadService";
import ChatMessage from "./components/ChatMessage";


const ConversationList = (props) => {

    

    const viewComponents = props.viewComponents;
    const ListView = viewComponents.listView;

    const loggedInUser = authUser();

    const entity_id = props.entity_id;
    const entity_type = props.entity_type;
    const preSelectedConversationConsultantId = props.consultant_id;
    // console.log({preSelectedConversationConsultantId});
    const [list, setList] = useState([]);
    const [conversationId, setConversationId] = useState(props.conversation_id || null);

    const [drawerVisible, setDrawerVisible] = useState(true);
    const [files, setFiles] = useState([]);

    const onFileUpload = (e) => {
        console.log("selected files", e.target.files);
    }

    const toggleDrawer = () => {
        // setDrawerVisible(!drawerVisible);
    };

    const select = (conv_id) => {
        setConversationId(conv_id);
    }

    const loadList = async () => {
        let resp = await fetchConversations({
            entity_id: entity_id,
            entity_type: entity_type,
            user_id: loggedInUser.user_id,
            user_type_id: loggedInUser.user_type_id
        });

        if(resp.status === 200){
            const _list = resp.data.list;
            setList(_list);
            if (preSelectedConversationConsultantId) {
                let conv = _list.find(conv => conv.consultant.consultant_id === preSelectedConversationConsultantId);
                if(typeof conv !== 'undefined'){
                    setConversationId(conv.id);
                }
                
            }
        }
    }

    useEffect(() => {
        setConversationId(props.conversation_id);
    }, [props.conversation_id]);

    useEffect(() => {
        loadList();
    }, [props.visible, entity_id, entity_type]);

    useEffect(() => {
        loadList();
    }, []);

    if(!props.visible){
        return <></>;
    }

    const anchor = 'right';
    console.log("props.conversation_id ===>", props.conversation_id, conversationId);
    
    if(conversationId){
        return (
            <div style={{ width: '400px', height: '400px'}}>
                {/* <Drawer
                    anchor={anchor}
                    open={drawerVisible}
                    onClose={() => props.onHide()}
                > */}
                    <ChatBox 
                        conversationId={conversationId}
                        user_id={loggedInUser.user_id}
                        user_type_id={loggedInUser.user_type_id}
                        viewComponents={{
                            header: viewComponents.header,
                            message: ChatMessage,
                            info: viewComponents.info
                        }} />
                {/* </Drawer> */}
            </div>
        )
    }
    
    return (
        <div>
            <Drawer
                anchor={anchor}
                open={drawerVisible}
                onClose={() => props.onHide()}
            >
                <ListView 
                    toggleDrawer={toggleDrawer}
                    list={list}
                    select={select} />
            </Drawer>
        </div>
    );
};

export default ConversationList;
