import Cookies from "js-cookie";
import ReactGA from "react-ga";
import { FetchSignURLService } from "Services/FileDownloadService";
import jwt_decode from "jwt-decode";
import { is } from "date-fns/locale";

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const alphabatesRgex = /^[A-Za-z\s]+$/;

export const alphaNumericRgex = /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/;

export const microsoftRegex = /(https:\/\/teams\.microsoft\.com\/[ A-Za-z0-9_@./#&+-=%])/g;

export const whatsAppRegex = /(https:\/\/chat\.whatsapp\.com\/[ A-Za-z0-9_@./#&+-=%])/g;

export const slack = /(https:\/\/[a-zA-Z0-9_-]+.slack\.com\/[ A-Za-z0-9_@./#&+-=%])/g;

export const isLogin = () => {
  let token = Cookies.get("token");
  return token ? true : false;
};

export const authUser = () => {
  let data = jwt_decode(Cookies.get("token"));
  let user = {
    name: data.name,
    email: data.email,
    user_type: usertype(data.user_type_id),
    user_type_id: data.user_type_id,
    user_id: data.user_id,
    // user_id: 1,
    user_types: data.user_types,
    // user_profile_image: Cookies.get("user_profile_image"),
  };
  return user;
};

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString();
};

export const dateForm = (date) => {
  let temp_date = new Date(date);
  let year = temp_date.getFullYear();
  let temp_month = temp_date.getMonth() + 1;
  let month = temp_month < 10 ? `0${temp_month}` : temp_month;
  let day = temp_date.getDate();
  return `${year}-${month}-${day}`;
};

export const dateFormWithMonthName = (date) => {
  let temp_date = new Date(date);
  let year = temp_date.getFullYear();
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let month = months[temp_date.getMonth()];
  let day = temp_date.getDate().toString().padStart(2, "0");
  return `${day} ${month} ${year}`;
};
export const getHashFromId = (id) => {
  return (1000000 + parseInt(id) * 10).toString(32).toUpperCase();
};

export const getIdFromHash = (_id) => {
  return (parseInt(_id, 32) - 1000000) / 10;
};

export const isEmail = (email) => {
  return email && emailRegex.test(email);
};

export const usertype = (type) => {
  if (type === 1) return "Client";
  if (type === 2) return "Consultant";
  if (type === 3) return "Admin";
};

export const fileDownload = async (id, name) => {
  const res = await FetchSignURLService(id);
  if (res) {
    fetch(res?.data?.signed_url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", name);
        a.click();
      });
    });
  }
};

export const durationTypes = [{ value: "Hour(s)" }, { value: "Day(s)" }, { value: "Week(s)" }, { value: "Month(s)" }];
export const durationTypesLeadEdit = [{value : "per hour", key : 'Hours'}, {value : "per day", key : 'Days'}, {value : "per week", key : 'Weeks'}, {value : "per month", key:'Months'}]

export const leadStatusType = [
  { name: "Demand Generation", color: "#141763", value: 3, isDisabled: false},
  { name: "Open for Application", color: "#607EBB", value: 1, isDisabled: false },
  { name: "Under client evaluation", color: "#42f581", value: 4, isDisabled: false},
  { name: "Ongoing Project", color: "#21BA45", value: 0, isDisabled: false },
  { name: "Project Completed", color: "#f59842", value: 5, isDisabled: false},
  { name: "Project discontinued", color: "#F25C5C", value: 2, isDisabled: false },
  {name: "On Hold", color: "#f50202", value: 6, isDisabled: false}
];


export const UnapprovedLeadStatusType = [
  { name: "Unapproved", color: "#2d8dec", value: 1, isDisabled: false},
  { name: "Approved", color: "#0ff16d", value: 2, isDisabled: false },
  { name: "Achived", color: "#ec2f16", value: 3, isDisabled: false }, 
];



export const requirementStatusType = [
  { name: "Open for Applicaton", value: 1 },
  { name: "Closed for Application", value: 0 },
];

export const locationPreferenceType = [
  { name: "Both", value: -1 },
  { name: "On Site Possible", value: 0 },
  { name: "Remote Only", value: 1 },
  // { name: "Part Time", value: 2 },
];

export const mailStatusType = [
  {name: "mail sent", value: "mail sent"}, 
  {name: "not sent", value: "not sent"}, 
];

export const responseStatusType = [
  {name: "Yes", value: "Yes"}, 
  {name: "No", value: "No"}, 
];

export const profileAttachmentType =  [
  {name: "Resume", value: "resume"}, 
  {name: "LinkedIn", value: "linkedIn"}, 
  {name: "OnePager", value: "onepager"}, 
];

export const engagementPreferenceType = [
  { name: "Both", value: 0 },
  { name: "Full Time", value: 1 },
  { name: "Part Time", value: 2 },
];

export const status = [
  { label: "In Progress", value: 1 },
  { label: "Completed", value: 2 },
];

export const initGA = (path) => {
  ReactGA.initialize("UA-214135487-1");
  ReactGA.pageview(path);
};

export const statusSelectType = [
  {name: "Applied", value: "Applied"}, 
  {name: "Verified", value: "Verified"},
  {name: "Rejected", value: "Rejected"},
  {name: "Shortlisted by client", value: "Shortlisted by client"},
  {name: "Declined by client", value: "Declined by client"}
]

export const validateNull = (key, data) => {
  let dataList = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].name) {
        key === "industry"
          ? dataList.push(data[i])
          : dataList.push({
              value: data[i].id,
              label: data[i].name,
            });
      }
    }
  }
  return dataList;
};

export const removeNull = (data) => {
  let result = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id !== null && data[i].name !== null) {
        result.push(data[i]);
      }
    }
  }
  return result;
};

export const formatData = (industries) => {
  let result = [];
  const temp_industries = removeNull(industries);
  if (temp_industries) {
    temp_industries.map((i) => {
      if (i.name) {
        result.push({ label: i.name, value: i?.id || i.Id });
      }
    });
  }
  return result;
};

export const formatDataFk = (industries) => {
  let result = [];
  const temp_industries = removeNull(industries);
  if (temp_industries) {
    temp_industries.map((i) => {
      if (i.name) {
        result.push({ label: i.name, value: i?.FK_industry_id || i.FK_industry_id });
      }
    });
  }
  return result;
};

export const isNumber = (value) => {
  if (value && isNaN(value)) {
    return true;
  } else {
    return;
  }
};

export const isNumberMessage = (value) => {
  if (value && isNaN(value)) {
    return "Enter numeric input.";
  } else {
    return;
  }
};

export const isAnyError = (value) => {
  const errorValues = Object.values(value);
  return errorValues.some((value) => value !== false);
};

export const isValidString = (value) => {
  if (value && isNaN(value)) {
    return false;
  }

  return true;
};

export const isRequired = (value) => {
  if (!value) {
    return true;
  } else {
    return;
  }
};

export const isRequiredMessage = (key, value) => {
  if (!value) {
    return `${key} is required`;
  } else {
    return;
  }
};

export const isRequiredLength = (value, length) => {
  if (!value) {
    return true;
  } else if (value && value.length > length) {
    return true;
  } else {
    return;
  }
};

export const isRequiredLengthMessage = (key, value, length) => {
  if (!value) {
    return `${key} is required`;
  } else if (value && value.length > length) {
    return `Maximum length is ${length}`;
  } else {
    return;
  }
};

export const isDuration = (duration, duration_type) => {
  if (!duration && duration_type) {
    return true;
  } else if (duration && isNaN(duration)) {
    return true;
  } else {
    return;
  }
};

export const isDurationMessage = (duration, duration_type) => {
  if (!duration && duration_type) {
    return " Duration is required.";
  } else if (duration && isNaN(duration)) {
    return "Enter numeric input.";
  } else {
    return;
  }
};

export const isDurationType = (duration, duration_type) => {
  if (duration && !duration_type) {
    return true;
  } else {
    return;
  }
};

export const isDurationTypeMessage = (duration, duration_type) => {
  if (duration && !duration_type) {
    return "Duration type is required.";
  } else {
    return;
  }
};

export const getID = (value) => {
  let data = [];
  if (value) {
    value.map((i) => {
      data.push(i.id);
    });
  }
  return data;
};


export const getformattedUrl = (url) => {
  var httpString = "http://";
  var httpsString = "https://";
  if (url.substr(0, httpString.length).toLowerCase() !== httpString && url.substr(0, httpsString.length).toLowerCase() !== httpsString){
    url = httpString + url;
  }
                
  
  return url;
};

export const getLeadStatusLead = (dataProjectLeadDetails) => {

  let label = ""

  if (dataProjectLeadDetails.lead_status == 0) {
      label = "Ongoing Project";
  } else if (dataProjectLeadDetails.lead_status == 1) {
      label = `Open for Application ${dataProjectLeadDetails.client_review_status === "Approved" ? "(Approved)" : `${authUser().user_type_id === 3 ? `(${{ "Pending": 'Approval Pending', 'Read': 'Client has visited', 'Sent': 'Pending', 'Approved': 'Approved'}[dataProjectLeadDetails.client_review_status]})` : "(Approval Pending)"}`}`;
  } else if (dataProjectLeadDetails.lead_status == 2) {
      label = "Project Discontinued";
  } else if (dataProjectLeadDetails.lead_status == 3) {
      label = "Demand Generation";
  } else if (dataProjectLeadDetails.lead_status == 4) {
      label = "Under client evaluation";
  } else if (dataProjectLeadDetails.lead_status == 5) {
      label = "Project Completed";
  } else if (dataProjectLeadDetails.lead_status == 6) {
      label = "On Hold";
  }

  return label;
}

export const getUnapprovedLeadStatusLead = (dataProjectLeadDetails) => {

  let label = ""

  if (dataProjectLeadDetails.status == 1) {
      label = "Unapproved";
  } else if (dataProjectLeadDetails.status == 2) {
      label = "Approved";
  } else if (dataProjectLeadDetails.status == 3) {
      label = "Archived";
  }
   

  return label;
}


export const getApprovalStatus = (dataProjectLeadDetails) => {
   let label = "";
   label = `${dataProjectLeadDetails.client_review_status === "Approved" ? "Approved" : `${authUser().user_type_id === 3 ? `${{ "Pending": 'Approval Pending', 'Read': 'Client has visited', 'Sent': 'Pending', 'Approved': 'Approved'}[dataProjectLeadDetails.client_review_status]}` : "Approval Pending"}`}`;
   return label;
}


export const convertTZ = (date, tzString, toUTC= false) => {

  let converted_date = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString})); 
  return converted_date;
}

export const extractTimeFromDateString = (dateString) =>  {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Get the time components
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Pad hours, minutes, and seconds with leading zeros if needed
  const pad = (num) => num.toString().padStart(2, '0');
  const formattedTime = `${pad(hours)}:${pad(minutes)}`;

  return formattedTime;
}

export const extractDate = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;

}

export const getUserTimeZone = () =>  {
  // Get the IANA timezone string
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get the current timezone offset in minutes
  const offsetInMinutes = new Date().getTimezoneOffset();
  
  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
  const offsetMinutes = Math.abs(offsetInMinutes) % 60;
  const sign = offsetInMinutes > 0 ? '-' : '+';

  // Format the offset string
  const formattedOffset = `UTC${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;

  return {
    timeZone: timeZone,
    displayTimeZone: `${timeZone} (${formattedOffset})`
  };
}


export const formatNumberWithCommas = (amount) => {
  const currencyOptions = { 
    style: 'currency', 
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  };
  
  const formattedAmount = amount.toLocaleString('en-US', currencyOptions);

  return formattedAmount;
}

export const validateRequirements = (data) => {
  let errors = [];
  let isValid = true;
   data.map((item, index) => {
      let state = {a: false, b: false, c: false, d: false, e: false, f: false}

      if (!item.requirement_title || item.requirement_title.trim() === "") {
        isValid = false;
        state = {...state, e: "Requirement title is required"};
      }

      if (!item.number_of_consultant || item.number_of_consultant <= 0) {
          isValid = false;
          state = {...state, a: "Number of consultants is required"};
      }

      if (!item.capabilities || item.capabilities.length === 0) {
          isValid = false;
          state = {...state, f: "Capabilities is required"};
      }

      errors.push(state)

    });

    return {
      isValid: isValid,
      errors: errors
    };
  }