import React, { useState, useEffect } from "react";
import { useTheme, useMediaQuery, Grid, Box, Button, TextField, Avatar, Typography, IconButton, IMG } from "Assets/Material";
import { FileUploadIcon, ChatSend, ChatFile } from "Assets/Icons";
import { FileUploadService, imageUploadService } from "Services/FileUploadService";

const MessageForm = (props) => {

    const [files, setFiles] = useState({});
    const [progress, setProgress] = useState(0);
    const [progressIndex, setProgressIndex] = useState(0);
    const [uploading, setUploading] = useState(false);

    const resetUpload = () => {
        setFiles({});
        setProgress(0);
        setProgressIndex(0);
    }

    const onFileSelection = async (files) => {
        console.log("selected files", files);
        let _files = {};
        for( let i = 0, l = files.length; i < l; i++){
            _files[`${i}`] = {
                file: files[i]
            }
        }
        console.log(_files);
        setFiles(_files);
        setUploading(true);

        let _ids = [];
        for (let i = 0, l = files.length; i < l; i++){
            const option = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    console.log('progress on', `${i}`);
                    setProgress(percent);
                    setProgressIndex(`${i}`);
                },
            };
            const response = await imageUploadService(files[`${i}`], option);
            const data = response.data[0];
            console.log({ data });
            _ids.push({
                id: data.asset_id,
                name: files[`${i}`].name
            });
            setFiles((currFiles) => {
                currFiles[`${i}`].complete = true;
                return currFiles;
            })
        }
        
        
        props.setFiles(_ids);
        setUploading(false);
        
    }

    return (
        <Box sx={{ padding: 0 }}>
            {
                Object.keys(files).length > 0
                ?   (
                    <Box container spacing={1} sx={{padding: 1, background: '#EEE'}}>
                        {Object.keys(files).map((key, k) => {
                            const file = files[key];
                            return (
                                <Box item xs={12} key={k}>
                                    <Typography sx={{ fontSize: '0.675rem' }}>{file.file.name} {file.complete ? '✓' : ''}{progressIndex === key && uploading ? `uploading (${progress}%)` : ''}</Typography>
                                </Box>
                            )
                        })}
                    </Box>
                )
                :   <></>
            }
            <Grid container alignItems={'center'}>
                <Grid item xs="auto" >
                    <Typography htmlFor="chatfiles" component="label" sx={{padding: '0.5rem'}}>
                        <IMG src={ChatFile} />
                    </Typography>

                    <input
                        type="file"
                        id="chatfiles"
                        style={{ display: "none" }}
                        multiple
                        onChange={(e) => onFileSelection(e.target.files)}
                    // accept="file/pdf, file/doc, file/docx, file/xls, file/xlsx, file/ppt, file/pptx"
                    />
                </Grid>
                <Grid item xs sx={{grow: 1}}>
                    <TextField multiline
                        
                        onChange={(e) => props.onTextChange(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === "Enter" && !uploading) {
                                props.sendMessage();
                                resetUpload();
                                return false;
                            }
                        }}
                        value={props.text}
                        placeholder='write here'
                        sx={{ width: '100%', padding: '0.5rem', borderRadius: '1rem', lineHeight: 1.1 }} size="small" />
                </Grid>
                <Grid item xs="auto">
                    
                    <Button 
                        onClick={() => {
                            props.sendMessage();
                            resetUpload();
                        }}
                        disabled={uploading} 
                        variant='image' >
                        <IMG src={ChatSend} />
                        </Button>
                    {/* <IconButton onClick={() => { }}>
                                                    <ChatSend />
                                                </IconButton> */}
                </Grid>
            </Grid>
        </Box>
    )
};

export default MessageForm;